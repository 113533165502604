<template>
    <div>
        <el-dialog :visible.sync="stateVisible" width="800px" :before-close="handleClose" custom-class="stateClass"
            :close-on-click-modal="false">
            <div slot="title">
                <div class="state_title">
                    <i class="iconfont icon-buhuanlingdengjizhengshu"></i>
                    {{
                        cert_test.status == 0 ? '证书待申请' : cert_test.status == 1 ? '证书审核中' : cert_test.status == 2 ?
                            '证书审核中' : cert_test.status == 3 ? '证书申请已驳回' : '证书已获得'
                    }}
                </div>
                <div class="state_msg" v-if="cert_test.status == 0">恭喜学完《{{ goodsTitle }}》，可自主申请证书</div>
                <div class="state_msg state_kemsg state_ketitle" v-if="cert_test.status != 0">课程名称：{{ goodsTitle }}
                </div>
                <div class="state_msg state_kemsg" v-if="cert_test.status != 0">申请日期：{{ cert_apply.add_time |
                    formatimes('yyyy-MM-dd hh:mm:ss') }}</div>
                <div class="reject" v-if="cert_test.status == 3">
                    <p>驳回理由</p>
                    {{ cert_apply.cause }}
                </div>
                <div class="user_msg" v-if="cert_test.status == 1 || cert_test.status == 2 || cert_test.status == 4">
                    姓名：<span>{{ cert_apply.full_name }}</span>
                </div>
                <template
                    v-if="cert_apply.consignee_name !='' && (cert_test.status == 1 || cert_test.status == 2 || cert_test.status == 4)">
                    <div class="user_msg">
                        邮寄信息：<span>{{ cert_apply.consignee_name }} {{ cert_apply.consignee_phone }}</span>
                    </div>
                    <div class="user_msg user_address">
                        <span>{{ areaText }} {{ cert_apply.consignee_address }}</span>
                    </div>
                </template>
            </div>
            <form v-if="cert_test.status == 0 || cert_test.status == 3">
                <table style="margin: 0 auto;width:658px;">
                    <tr>
                        <td class="tab_lable"><span>*</span> 姓名 </td>
                        <td width="550"><el-input type="text" placeholder="请输入姓名" v-model="userinfo.username"
                                maxlength="30" show-word-limit></el-input></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td width="550" class="username_msg">该姓名用在证书上，请正确填写</td>
                    </tr>
                </table>
                <el-divider></el-divider>
                <table style="margin: 0 auto;width:658px;" class="tab_address">
                    <template v-if="cert_apply.is_supply_post == 1">
                        <tr>
                            <td class="tab_lable tab_info">邮寄信息</td>
                            <td width="550" class="tab_reset"><span @click="resetInfo">清空地址</span></td>
                        </tr>
                        <tr>
                            <td class="tab_lable"><span v-if="cert_apply.supply_post_val == 1">*</span>收货人 </td>
                            <td width="550"><el-input type="text" placeholder="请填写收货人" v-model="userinfo.consignee"
                                    maxlength="30" show-word-limit></el-input></td>
                        </tr>
                        <tr>
                            <td class="tab_lable"><span v-if="cert_apply.supply_post_val == 1">*</span>手机号 </td>
                            <td width="550"><el-input type="text" placeholder="请填写收货人手机号" v-model="userinfo.phone"
                                    maxlength="11" show-word-limit></el-input></td>
                        </tr>
                        <tr>
                            <td class="tab_lable"><span v-if="cert_apply.supply_post_val == 1">*</span>所在区 </td>
                            <td width="550"><el-cascader v-model="userinfo.area" :options="options"
                                    :props="{ expandTrigger: 'hover', value: 'name', label: 'name', children: 'child' }"
                                    @change="handleChange" popper-class="area_content"></el-cascader></td>
                        </tr>
                        <tr>
                            <td class="tab_lable td_top"><span v-if="cert_apply.supply_post_val == 1">*</span>详细地址 </td>
                            <td width="550"><el-input type="textarea" placeholder="请填写详细地址（街道、楼牌号等）"
                                    v-model="userinfo.areainfo" maxlength="100" show-word-limit :rows="3"></el-input>
                            </td>
                        </tr>
                    </template>
                    <tr v-if="cert_apply.is_file == 1">
                        <td class="tab_lable td_top"><span>*</span> 上传附件 </td>
                        <td width="550">
                            <el-upload class="upload_file" ref="upload" action="/api/uploader/v1/upload_file"
                                :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList"
                                :on-success="handleSuccess" :limit="1" :headers="{
                                    'Uxb-User-Agent': 'webVersion:v1.0.0',
                                }" :data="fileData" :before-upload="beforeUpload" accept=".pdf" :multiple="true"
                                :on-exceed="limitTips">
                                <el-button slot="trigger" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">
                                    <p>请下载附件模板，并按要求填写</p>
                                    <p>上传时请传pdf格式文件，大小不超过1M</p>
                                    <div class="file_icon" @click="downloadTemplate">
                                        <i class="iconfont icon-doc-line"></i>
                                        附件模板：高中研究性课题报告格式.doc
                                    </div>
                                </div>
                            </el-upload>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align:center;">
                            <el-button type="primary" round @click="tipsShow(1)"
                                v-if="cert_test.status == 0">确认申请</el-button>
                            <el-button type="primary" round @click="tipsShow(2)" v-else>重新申请</el-button>
                        </td>
                    </tr>
                </table>
            </form>
            <div class="certificate_info" v-if="cert_test.status == 4 || cert_test.status == 1 || cert_test.status == 2">
                <p v-if="fileList.length>0">{{ cert_test.status==4?'报告下载：':'已传附件：' }}</p>
                <a v-for="item in fileList" :key="item.uuid" :href="item.url" target="_blank">{{ item.name + '.pdf'
                    }}</a>
                <img :src="picUrl" class="certificate_pic" v-if="cert_test.status == 4">
                <span class="certificate_download" @click="downloadImage" v-if="cert_test.status == 4">下载证书</span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { pcaTextArr, regionData, codeToText } from 'element-china-area-data'
import { addCertificate, certificate, addAddress, editAddress, listAddress, areaTree } from '../assets/api'
import { changeStr, pinjie, objKeySort } from "../assets/sign";
import Md5 from "md5";
import $ from 'jquery'
export default {
    name: 'apply_state',
    data () {
        return {
            stateVisible: false,
            options: [],
            userinfo: {
                username: '',
                consignee: '',
                phone: '',
                area: '',
                areainfo: '',
            },
            fileList: [],
            picUrl:'',
            cert_test: {},
            goodsTitle: '',
            cert_apply: {},
            fileData: {
                siteid: '',
                bucket_type: 2,
                up_config: 1,
                uploadfiletype: 1,
                operation_mode: 1,
                stime: '',
                sign: '',
            },
            uuid: [],
            downloadDoc: '//uxbpubtest.uxiaobang.com/55/2024/12/04/dc82451d-0796-4197-a042-2afa2a0bc4fa.doc',
            addressId: 0,
            areaText: '',
            long: 0
        }
    },
    mounted () {
        areaTree().then(res => {
            if (res.code == 0) {
                res.data.list_tree.map(one => {
                    one.child.map(two => {
                        two.child.map(three => {
                            delete three.child
                        })
                    })
                })
                this.options = res.data.list_tree
            }
        })
    },
    methods: {
        limitTips () {
            this.$message.error('最多只能上传1个附件！');
        },
        // 确认消息框
        tipsShow (status) {
            this.$emit('tipsShow', status)
        },
        // 提交申请或重新申请
        doSubmit (status) {
            this.applyForCertificate(status)
        },
        openInit (cert_test, goodsTitle) {
            // console.log(cert_test,goodsTitle);
            this.stateVisible = true
            this.cert_test = cert_test
            this.goodsTitle = goodsTitle
            let params = {
                apply_id: cert_test.apply_id
            }
            certificate(params).then(res => {
                // console.log(res,8899);
                if (res.code == 0) {
                    this.cert_apply = res.data.cert_apply
                    this.picUrl = res.data.cert_apply.image
                    if (this.cert_apply.consignee_area != '') {
                        this.areaText = this.cert_apply.consignee_area.split('/').join('')
                    }
                    if (this.cert_apply.status == 0) {
                        listAddress().then(res => {
                            if (res.code == 0) {
                                if (res.data.list.length != 0) {
                                    this.addressId = res.data.list[0].id
                                    let area = res.data.list[0].province_name + '/' + res.data.list[0].city_name + '/' + res.data.list[0].county_name
                                    this.userinfo.consignee = res.data.list[0].name
                                    this.userinfo.phone = res.data.list[0].phone
                                    this.userinfo.area = area.split('/')
                                    this.userinfo.areainfo = res.data.list[0].address
                                    // this.userinfo = {
                                    //     consignee: res.data.list[0].name,
                                    //     phone: res.data.list[0].phone,
                                    //     area: area.split('/'),
                                    //     areainfo: res.data.list[0].address,
                                    // }
                                } else {
                                    this.userinfo.consignee = this.cert_apply.consignee_name
                                    this.userinfo.phone = this.cert_apply.consignee_phone
                                    this.userinfo.area = this.cert_apply.consignee_area.split('/')
                                    this.userinfo.areainfo = this.cert_apply.consignee_address
                                    // this.userinfo = {
                                    //     consignee: this.cert_apply.consignee_name,
                                    //     phone: this.cert_apply.consignee_phone,
                                    //     area: this.cert_apply.consignee_area.split('/'),
                                    //     areainfo: this.cert_apply.consignee_address,
                                    // }
                                }
                            }
                        })
                    } else {
                        listAddress().then(res => {
                            if (res.code == 0) {
                                this.addressId = res.data.list[0].id
                            }
                        })
                        this.userinfo.consignee = this.cert_apply.consignee_name
                        this.userinfo.phone = this.cert_apply.consignee_phone
                        this.userinfo.area = this.cert_apply.consignee_area.split('/')
                        this.userinfo.areainfo = this.cert_apply.consignee_address
                        // this.userinfo = {
                        //     consignee: this.cert_apply.consignee_name,
                        //     phone: this.cert_apply.consignee_phone,
                        //     area: this.cert_apply.consignee_area.split('/'),
                        //     areainfo: this.cert_apply.consignee_address,
                        // }
                    }

                    this.userinfo.username = this.cert_apply.full_name
                    this.fileList = []
                    this.cert_apply.file_list.map((item, index) => {
                        let flies = {}
                        flies.name = (index + 1) + '. ' + item.file_name + '.pdf'
                        flies.url = item.path
                        flies.uuid = item.uuid
                        this.fileList.push(flies)
                        this.uuid.push(item.uuid)
                        // console.log(this.fileList);
                    })
                }
            })
        },
        // 申请证书
        applyForCertificate (status) {
            if (this.userinfo.username == '') {
                this.$message.error('姓名用在证书上，请正确填写');
                return
            }
            if (this.uuid.length == '' && this.cert_apply.is_file == 1) {
                this.$message.error('请上传附件！');
                return
            }
            if (this.cert_apply.is_supply_post == 1 && this.cert_apply.supply_post_val == 1) {
                if (this.userinfo.consignee == '') {
                    this.$message.error('请输入收货人姓名');
                    return
                }
                if (this.userinfo.phone == '') {
                    this.$message.error('请输入收货人手机号');
                    return
                }
                if (this.userinfo.area == '') {
                    this.$message.error('请输入收货所属地区');
                    return
                }
                if (this.userinfo.areainfo == '') {
                    this.$message.error('请输入收货人地址');
                    return
                }
                let province, city, county
                this.options.map(one => {
                    if (one.name == this.userinfo.area[0]) {
                        province = one.coding
                        one.child.map(two => {
                            if (two.name == this.userinfo.area[1]) {
                                city = two.coding
                                two.child.map(three => {
                                    if (three.name == this.userinfo.area[2]) {
                                        county = three.coding
                                    }
                                })
                            }
                        })
                    }
                })
                let params = {
                    is_default: 1,
                    name: this.userinfo.consignee,
                    phone: this.userinfo.phone,
                    province: province,
                    city: city,
                    county: county,
                    address: this.userinfo.areainfo
                }
                if (this.addressId == 0) {
                    addAddress(params).then(res => {
                        console.log(res, '添加');
                    })
                } else {
                    params.id = this.addressId
                    editAddress(params).then(res => {
                        console.log(res, '编辑');
                    })
                }
            }

            let params = {
                apply_id: this.cert_test.apply_id,
                certificate_type: this.cert_test.certificate_type,
                cert_name: this.userinfo.username,
                status: status,
                consignee_name: this.cert_apply.is_supply_post == 1?this.userinfo.consignee:'',
                consignee_phone: this.cert_apply.is_supply_post == 1?this.userinfo.phone:'',
                consignee_area: this.cert_apply.is_supply_post == 1?this.userinfo.area.join('/'):'',
                consignee_address: this.cert_apply.is_supply_post == 1?this.userinfo.areainfo:'',
                uuid: this.cert_apply.is_file == 1?[this.uuid[0]]:[]
            }
            addCertificate(params).then(res => {
                if (res.code == 0) {
                    this.$message({
                        message: '申请成功，请等待审核！',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.stateVisible = false
                    }, 2000);
                }
            })
        },
        handleClose () {
            this.stateVisible = false
        },
        handleChange (value) {
            // let dz = ''
            // if (value[1] != null && value[2] != null) {
            //     let dz1 = codeToText[value[0]]
            //     // let dz2 = codeToText[value[1]]=='市辖区'?codeToText[value[0]]:codeToText[value[1]]
            //     let dz2 = codeToText[value[1]]
            //     let dz3 = codeToText[value[2]]
            //     dz = dz1 + '/' + dz2 + '/' + dz3
            // }
            // console.log(value);
        },
        handleRemove (file, fileList) {
            let hasKey = 'response' in file
            let uuid = ''
            if (hasKey) {
                uuid = file.response.data.uuid
            } else {
                uuid = file.uuid
            }
            this.uuid = this.uuid.filter(item => item != uuid)
            fileList.map((item, index) => {
                let arr = item.name.split('.');
                arr.shift()
                item.name = (index + 1) + '.' + arr.join('.')

            })
            this.long--
        },
        handlePreview (file) {
            // console.log(file);
        },
        handleSuccess (response, file, fileList) {
            this.uuid.push(response.data.uuid)
            fileList[this.long].name = `${this.long + 1}. ${fileList[this.long].name}`
            this.long++
            // console.log(file,response);
            // if (this.long == 1) {
            //     this.uuid.push(response.data.uuid)
            //     let index = fileList.length - 1
            //     fileList.map((item , ind)=> {
                    
            //         if(ind==index){
            //             item.name = `${index+1}. ${item.name}`
            //             index++
            //         }
            //     })
            //     console.log(fileList);
            // }
            // if(this.long==fileList.length){
            //     this.long = 0
            // }
        },
        beforeUpload (file) {
            const isLtSize = file.size / 1024 < 1024
            if (!isLtSize) {
                this.$message.error(`上传附件大小不能超过 1M!`)
                return false
            }
            this.fileData.stime = ''
            this.fileData.sign = ''
            this.fileData.siteid = JSON.parse(window.sessionStorage.getItem('infos')).siteid
            this.fileData.stime = parseInt(
                new Date().getTime() / 1000
            )
            this.fileData.sign = Md5(
                changeStr(pinjie(objKeySort(this.fileData)))
            )


        },
        resetInfo () {
            this.userinfo = {
                username: '',
                consignee: '',
                phone: '',
                area: '',
                areainfo: '',
                fileList: []
            }
        },
        downloadImage () {
            let arr = this.picUrl.split('.')
            let suffix = arr[arr.length - 1]
            let imageUrl = this.picUrl
            let link = document.createElement('a');
            link.href =  `${imageUrl}?response-content-type=application/octet-stream`;
            link.target = '_blank'
            link.download = 'certificate.' + suffix;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        downloadTemplate () {
            let arr = this.downloadDoc.split('.')
            let suffix = arr[arr.length - 1]
            let docUrl = this.downloadDoc
            let link = document.createElement('a');
            link.setAttribute('href', docUrl);
            link.setAttribute('download', '高中研究性课题报告格式.' + suffix);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

    }
}
</script>

<style lang="scss" scoped>
/deep/ .stateClass {
    border-radius: 20px;
    background: #F8F8F8;

    .el-dialog__header {
        border: none !important;
        background: #F8F8F8;
        border-radius: 20px 20px 0 0;
        padding: 30px;

        .state_title {
            font-size: 26px;
            font-weight: bold;
            color: #333;

            i {
                font-weight: normal;
                font-size: 26px;
                color: var(--change-color);
            }
        }

        .state_msg {
            font-size: 18px;
            color: #666;
            line-height: 48px;

        }

        .user_msg {
            font-size: 18px;
            color: #666;
            line-height: 36px;

            span {
                color: #333;
            }
        }

        .user_address {
            padding-left: 92px;
        }

        .state_kemsg {
            line-height: 36px;
        }

        .state_ketitle {
            padding-top: 6px;
        }

        .reject {
            // width:740px;
            // height: 98px;
            border: 1px solid #94070A;
            border-radius: 8px;
            background: #F8EDEB;
            padding: 0 20px;
            color: #94070A;
            font-size: 18px;
            line-height: 36px;
            margin: 10px 0;

            p {
                color: #999999;
                padding-top: 10px;
            }
        }
    }

    .el-dialog__body {
        background: #fff;
        border-radius: 20px;
        padding: 30px;

        .certificate_info {
            p {
                font-size: 16px;
                color: #333;
                line-height: 32px;
            }

            a {
                font-size: 16px;
                line-height: 32px;
                display: block;
            }

            .certificate_pic {
                display: block;
                width: 400px;
                margin: 30px auto;
            }

            .certificate_download {
                cursor: pointer;
                display: block;
                margin: 0 auto;
                width: 124px;
                height: 44px;
                border-radius: 22px;
                background: #95060B;
                color: #fff;
                font-size: 16px;
                text-align: center;
                line-height: 44px;
            }
        }

        .tab_address {
            td {
                padding: 7px 0;
            }

            .tab_info {
                font-size: 16px;
                font-weight: bold;
                color: #333;
            }

            .tab_reset {
                text-align: right;
                font-size: 16px;
                color: #4D63A1;

                span {
                    cursor: pointer;
                }
            }

            .tab_lable {
                padding-right: 14px;
            }

            .td_top {
                vertical-align: top;
                line-height: 48px;
            }

            .el-button--primary {
                width: 126px;
                height: 44px;
            }

            .upload_file {
                .el-button--primary {
                    background: none !important;
                    color: var(--change-color);
                    border: 1px solid var(--change-color) !important;
                    width: 126px;
                    height: 44px;
                }

                .el-upload-list__item-name {
                    white-space: inherit;
                }
            }

            .el-upload__tip {
                p {
                    font-size: 14px;
                    color: #999;
                    line-height: 24px;
                }

                .file_icon {
                    font-size: 16px;
                    color: #333;
                    cursor: pointer;

                    i {
                        font-size: 20px;
                        color: #999;
                    }

                    &:hover {
                        color: var(--change-color);
                    }
                }
            }
        }

        .tab_lable {
            text-align: right;
            font-size: 16px;
            color: #666;
            padding-right: 14px;

            span {
                color: #FE4D67;
            }
        }

        .el-input__inner {
            border: none;
            background: #F8F8F8;
            height: 48px;
        }

        .el-textarea__inner {
            border: none;
            background: #F8F8F8;
            padding: 15px;
        }

        .el-textarea .el-input__count {
            background: none;
        }

        .el-input__count-inner {
            background: none;
        }

        .username_msg {
            color: #F23333;
            font-size: 14px;
            height: 34px;
        }

        .el-cascader {
            width: 100%;
        }

    }
}
</style>
<style lang="scss">
.area_content {

    .el-cascader-node.in-active-path,
    .el-cascader-node.is-active,
    .el-cascader-node.is-selectable.in-checked-path {
        color: var(--change-color);
    }
}
</style>